
/* eslint-disable vue/no-dupe-keys */
import {
  defineComponent,
  toRefs
} from 'vue'
import type { PropType } from 'vue'
import moment from 'moment'
import { TDeliveryStatusLogDelivery } from '@/data/source/remote/api/backend/V2/transPengirimanHds-model'

export default defineComponent({
  name: 'HjexTimeline',
  props: {
    logs: {
      type: Array as PropType<Array<TDeliveryStatusLogDelivery>>,
      default: () => []
    }
  },
  setup(props) {
    const {
      logs
    } = toRefs(props)

    return {
      moment,
      logs
    }
  }
})
