import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    id: _ctx.id,
    class: "light-gallery-plugin"
  }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.listImg, (img, key) => {
      return (_openBlock(), _createBlock("a", {
        "data-src": img.imgSrc,
        key: key,
        "data-download": img.alt,
        "data-download-url": img.file
      }, [
        (_ctx.$slots?.content)
          ? _renderSlot(_ctx.$slots, "content", {
              key: 0,
              image: img,
              index: key
            })
          : (_openBlock(), _createBlock("img", {
              key: 1,
              alt: img.alt,
              src: img.src
            }, null, 8, ["alt", "src"]))
      ], 8, ["data-src", "data-download", "data-download-url"]))
    }), 128))
  ], 8, ["id"]))
}